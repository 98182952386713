<template>
    <!--班级管理 > 成绩分析-->
    <div class="center-main">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div
              v-if="templateStatus != '5' && !loadingShow"
              class="content-wrapper"
            >
                <div class="filter-wrap1">
                    <div>
                        <expand-filter
                        :formData="formData"
                        @clickBtn="clickBtn"
                        margin-bottom="2px"
                        >
                        </expand-filter>
                        <p class="line" v-hasPermi="['achievement:examAdd']"></p>
                        <el-button  @click="createExam()" type="primary" v-hasPermi="['achievement:examAdd']">创建考试</el-button>
                    </div>
                    <div class="right-button">
                        <div class="zhkt">
                            <p>智慧课堂同步家长</p>
                            <el-tooltip effect="dark" content="勾选后同步的智慧课堂成绩将自动发送给家长" placement="top" :offset="30">
                                <i class="el-icon-question label-description" style="color: #B4B4B4;"></i>
                            </el-tooltip>
                            <el-checkbox  @change="changeCheckd" :true-label='"1"' :false-label="'0'" v-model="checked1"></el-checkbox>
                        </div>
                        <div class="xk-test" v-hasPermi="['achievement:synExamYx','achievement:synExamYy','achievement:synExamZhkt']">
                            <el-dropdown>
                                <xk-button type="warning">同步考试</xk-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="syncYxExamShow" v-hasPermi="['achievement:synExamYx']">云校园</el-dropdown-item>
                                    <el-dropdown-item @click.native="syncExamShow" v-hasPermi="['achievement:synExamYy']">云阅卷</el-dropdown-item>
                                    <el-dropdown-item @click.native="syncKtExamShow" v-hasPermi="['achievement:synExamZhkt']">智慧课堂</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>

                </div>
                <div class="table-paging">
                    <table-data
                      :config="table_config"
                      :to-top="false"
                      :tableData="examList"
                      v-loading="loadingTable"
                      backgroundHeader="#FAFBFC"
                      :headerStyle="{ height: '50px' }"
                      :rowStyle="{ height: '50px' }"
                      ref="table"
                      @handleSelectionChange="handleSelectionChange"
                    >
                        <!-- 考试名称 -->
                        <template #examName="{ data,index }">
                            <span
                              style="width:200px;color: #3C7FFF;cursor: pointer;overflow:hidden;white-space:nowrap;text-overflow: ellipsis;"
                              @click="tableBtnClick(data,index)"
                            >{{ data.examName }}</span>
                        </template>
                        <!-- 来源 -->
                        <template #sourceType="{ data }">
                            <span v-if="data.sourceType == 1">云校园</span>
                            <span v-if="data.sourceType == 2">云阅卷</span>
                            <span v-if="data.sourceType == 7">智慧课堂</span>
                            <span v-if="data.sourceType == 3">创建</span>
                        </template>
                        <!-- 同步家长 -->
                        <template #tbFamily="{ data }">
                            <span class="flex">
                                <div
                                  :class="data.isSendGradesCloud == 1 ? 'blue' : 'orange'"
                                ></div>
                                <span
                                  v-if="data.isSendGradesCloud == 1">已同步</span>
                                <span v-else>未同步</span>
                            </span>
                        </template>
                        <!-- 同步状态 -->
                        <template #syncScoreStatusCloud="{ data }">
                            <span class="flex">
                                <div v-if="data.syncScoreStatusCloud == 3" class="blue"></div>
                                <div v-if="data.syncScoreStatusCloud == 1 || data.syncScoreStatusCloud == null" class="orange"></div>
                                <div v-if="data.syncScoreStatusCloud == 4" class="purplr"></div>
                                <div v-if="data.syncScoreStatusCloud == 2" class="yellow"></div>
                                <span v-if="data.syncScoreStatusCloud == 1 || data.syncScoreStatusCloud == null">未同步</span>
                                <span v-if="data.syncScoreStatusCloud == 2">同步中</span>
                                <span v-if="data.syncScoreStatusCloud == 3">已同步</span>
                                <span v-if="data.syncScoreStatusCloud == 4">部分同步</span>
                            </span>
                        </template>
                        <!-- 导入状态 -->
                        <template #importState="{ data }">
                            <span class="flex">
                                <div v-if="data.isImport == 1" class="blue"></div>
                                <div v-if="data.isImport == 0 || data.isImport == null" class="orange"></div>
                                <div v-if="data.isImport == 2" class="purplr"></div>
                                <span v-if="data.isImport == 1">已导入</span>
                                <span v-if="data.isImport == 0 || data.isImport == null">未导入</span>
                                <span v-if="data.isImport == 2">部分导入</span>
                            </span>
                        </template>
                        <!-- 操作 -->
                        <template v-slot:operation="slotData">
                            <el-button
                              type="text"
                              v-hasPermi="['achievement:examEdit']"
                              @click="editExam(slotData.data)"
                            >编辑</el-button>
                            <el-button
                              v-hasPermi="['achievement:examSetting']"
                              @click="settingExam(slotData.data)"
                              type="text"
                            >设置</el-button>
                            <el-button
                              v-hasPermi="['achievement:examDel']"
                              @click="delExam(slotData.data)"
                              type="text"
                            >删除</el-button>
                            <el-button
                              @click="sendResults(slotData.data)"
                              type="text"
                              v-hasPermi="['achievement:sendScore']"
                            >给家长发送成绩</el-button>
                            <!-- 1云校2云阅卷7智慧课堂，3创建 -->
                            <el-button v-if="slotData.data.sourceType == '2'" @click="asyncGrade(slotData.data)" type="text" v-hasPermi="['achievement:synScorYy']">成绩同步</el-button>
                            <el-button v-if="slotData.data.sourceType == '7'" @click="asyncGrade(slotData.data)" type="text" v-hasPermi="['achievement:synScorZhkt']">成绩同步</el-button>
                            <el-button v-if="slotData.data.sourceType == '1'" @click="asyncGrade(slotData.data)" type="text" v-hasPermi="['achievement:synScorYx']">成绩同步</el-button>

                            <el-button type="text" @click="handleImport(slotData.data.id)" v-hasPermi="['achievement:importScore']">导入</el-button>
                        </template>
                    </table-data>
                    <div class="pageFooter">
                        <el-checkbox v-model="checkedList" class="checkbox" @change="chooseAll(examList)">当页全选</el-checkbox>
                        <xk-button class="typeButton" :disabled="typeDisabled" v-hasPermi="['achievement:modifyType']" :class="tableListSel.length > 0 ? 'active' : ''" @click="creatType">修改类型</xk-button>
                        <Pagination
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getExamList"
                        ></Pagination>
                    </div>
                </div>
                <!-- 设置考试 -->
                <dialog-wrapper
                  :dialog-obj="examSettingObj"
                  :margin-top="'6vh'"
                  @handleClose="handleClose(examSettingObj)"
                >
                    <exam-setting
                      :data="examSettingData"
                      @dl="examSettingDl"
                      @add="examSettingAdd"
                      @reset="examSettingReset"
                      @submit="examSettingSubmit"
                    />
                </dialog-wrapper>
                <!-- 创建考试 -->
                <dialog-wrapper
                  :dialog-obj="setDialog"
                  @handleClose="handleSetClose"
                >
                    <set-exam
                      ref="setRef"
                      :list="examList"
                      :rules="setRules"
                      :rules-form-data="setRulesFormData"
                      :rules-form="setRulesForm"
                      @close="handleSetClose"
                      @save="handleSetSave"
                    />
                </dialog-wrapper>
                <!-- 同步考试弹窗 -->
                <dialog-wrapper
                  :dialog-obj="syncExamDialogObj"
                  @handleClose="handleSyncExamDialogClose"
                >
                    <SyncExam
                      :grade-list="syncExamGradeList"
                      :list-query="syncExamListQuery"
                      :list="syncExamList"
                      :selected="syncExamParams.list"
                      @query="syncExamQuery"
                      @reset="syncExamReset"
                      @selChange="syncExamSelChange"
                      @submit="syncExams"
                      @cancel="handleSyncExamDialogClose"
                    />
                </dialog-wrapper>
                <!-- 同步云校考试弹窗 -->
                <dialog-wrapper
                  :dialog-obj="syncYxExamDialogObj"
                  @handleClose="handleSyncYxExamDialogClose"
                >
                    <SyncYxExam
                      :list-query="syncYxExamListQuery"
                      :list="syncYxExamList"
                      :selected="syncYxExamParams.list"
                      @query="syncYxExamQuery"
                      @reset="syncYxExamReset"
                      @selChange="syncYxExamSelChange"
                      @submit="syncYxExams"
                      @cancel="handleSyncYxExamDialogClose"
                    />
                </dialog-wrapper>
                <!-- 同步智慧课堂考试弹窗 -->
                <dialog-wrapper
                  :dialog-obj="syncKtExamDialogObj"
                  @handleClose="handleSyncKtExamDialogClose"
                >
                    <el-date-picker
                      v-model="zhktDate"
                      type="daterange"
                      :clearable="false"
                      value-format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                    <div style="text-align: center; margin-top: 10px">
                        <xk-button
                          type="primary"
                          @click="confirmKtSchool"
                        >确定</xk-button>
                        <xk-button
                          type="info"
                          @click="cancelKtSchool"
                        >取消</xk-button>
                    </div>
                </dialog-wrapper>
                <!-- 选择学校 -->
                <dialog-wrapper
                  :dialog-obj="syncYxSchoolDialogObj"
                  @handleClose="syncYxSchoolDialogObj.dialogVisible = false"
                >
                    <el-select
                      v-model="syncYxExamListQuery.schoolId"
                      filterable
                      style="width: 100%"
                    >
                        <el-option
                          v-for="item in yyjSchoolList"
                          :label="item.name"
                          :value="item.id"
                          :key="item.id"
                        />
                    </el-select>
                    <div style="text-align: center; margin-top: 10px">
                        <xk-button
                          type="primary"
                          @click="confirmYyjSchool"
                        >确定</xk-button>
                        <xk-button
                          type="info"
                          @click="cancelYyjSchool"
                        >取消</xk-button>
                    </div>
                </dialog-wrapper>
                 <!-- 导入成绩 -->
                <dialog-wrapper
                    :dialogObj="importDialogObj"
                    @handleClose="handleImportClose"
                >
                    <exam-import
                        ref="importRef"
                        :show="show"
                        :active="importActive"
                        :import-form="importForm"
                        :rank-options="importRankOptions"
                        :show-download="showDownload"
                        :upload-excel-header="uploadExcelHeader"
                        :system-excel-header="systemExcelHeader"
                        :key-map="keyMap"
                        :import-info="importInfo"
                        :success-data="successData"
                        :importLoading="importLoading"
                        @uploadChange="importUploadChange"
                        @uploadRemove="importUploadRemove"
                        @next="handleImportNext"
                        @before="handleImportBefore"
                        @close="handleImportClose"
                        @importSelChange="importSelChange"
                        @download="downloadImportTemplate"
                        @save="handleImportSave"
                    />
                </dialog-wrapper>
                <!-- 修改类型 -->
                <dialog-wrapper
                  :dialog-obj="setTypeDialog"
                  @handleClose="handleTypeClose"
                >
                    <set-type
                      ref="setTypeRef"
                      :rules="setType"
                      :rules-form-data="setTypeFormData"
                      :rules-form="setTypeForm"
                      @close="handleTypeClose"
                      @save="handleSetTypeSave"
                    />
                </dialog-wrapper>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>
<script>
import {
    DialogWrapper,
    // 工具函数
    throttle,
    Error,
    Loading,
    Pagination
} from "common-local";
import {
    downloadFile
} from "@/libs/utils";
// 云校园
import SyncYxExam from "./classManage/analysis/syncYxExam";
// AJAX
import { ClassManageModel } from "@/models/ClassManage.js";
// 考试设置
import examSetting from "./classManage/analysis/examSetting";

// 自定义按钮颜色
import { mapState } from "vuex";
import { getToken } from "@/libs/auth";
// 创建考试
import SetExam from "./classManage/analysis/setExam";
import sendExam from "./classManage/analysis/sendExam";
// 修改类型
import SetType from './classManage/analysis/setType.vue'

//筛选条件
import ExpandFilter from "./Sub/ExpandFilter";
// 表格
import TableData from "./Sub/TableData";
import RecordsList from '@/libs/pageListOptimal.js';
// 导入弹窗件
import examImport from "@/components/scrollWrapper/classManage/ExamImport/index.vue";
import {TestModel} from "@/models/test";
import XLSX2 from "xlsx";
// 云阅卷同步考试
import SyncExam from "./classManage/analysis/syncExam";

export default {
    components: {
        SetExam,
        sendExam,
        DialogWrapper,
        examSetting,
        Error,
        Loading,
        ExpandFilter,
        Pagination,
        TableData,
        SetType,
        examImport,
        SyncYxExam,
        SyncExam
    },
    data () {
        return {
            // 智慧课堂同步家长
            checked1:'0',
            // 导入弹窗配置
            importDialogObj: {
                title: "导入",
                dialogVisible: false,
                width: "900px",
            },
            show: false,
            importInfo: {
                tips: "上传的文件必须包含：学生所在年级、班级、学生姓名、性别、学号",
                tips2: "直接上传已有的成绩表，下一步建立对应关系",
                downloadTemplate: "下载学生成绩模板",
                uploadText: "上传填好的学生成绩表",
                sameInfo: "覆盖导入：更新相同唯一号学生的成绩信息",
            },
            importLoading: false,
            successData: 0,
            // 导入导入排名可选项
            importRankOptions: [],
            // 导入
            TestModel,
            // 导入步骤
            importActive: 0,
            showDownload: true,
            keyMapCache: {},
            keyMap: {},
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: false,
            total: 0,
            checkedList: false,

            checked: '0',
            show: false,
            // 导入步骤
            keyMap: {},
            // 上传表头数据
            uploadExcelHeader: [],
            // 系统标准字段名称
            systemExcelHeader: [],
            // 导入数据
            importForm: {
                file: "",
                schoolId: "",
                examId: "",
                errType: "1", // 1继续执行  2终止导入
                sameType: "1", // 1覆盖导入  2追加导入
                isRank: "2", // 是否导入排名1是2否
                rankList: [], // 导出模板排名字段
            },
            // 基础信息
            foundationInfo: {
                userId: "",
                schoolId: "",
                examId: "",
                gradeId: "",
                name: "",
                examinationMode: "",
            },
            // 左侧tab数据
            examId: "",
            examList: [],
            // 考试筛选
            examinationScreening: {
                schoolId: "",
                gradeIdList: "",
                examTypeList: "",
                examName: "",
            },

            templateStatus: "0", // 模版筛选
            // 公共数据
            schoolId: "",
            btnColor: "#ffffff",
            template_info: [],
            // 头部筛选
            formData: {
                data: [
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '考试名称',
                        key: 'examName'
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '年级',
                        key: 'gradeId',
                        list: []
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '考试类型',
                        key: 'examType',
                        list: []
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '来源',
                        key: 'sourceType',
                        list: [
                            {
                                label: '云校园',
                                value: 1
                            },
                            {
                                label: '云阅卷',
                                value: 2
                            },
                            {
                                label: '智慧课堂',
                                value: 7
                            },
                            {
                                label: '创建',
                                value: 3
                            }
                        ]
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: '查询',
                        fn: 'primary',
                    },
                    {
                        type: "enquiry",
                        text: '重置',
                        fn: 'reset',
                    },
                ],
                btnFormType: true
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "考试名称",
                        slotName: 'examName',
                        type: 'slot',
                        labelWidth: "350",
                        align: 'center',
                    },
                    {
                        label: "来源",
                        slotName: "sourceType",
                        type:'slot',
                        align: 'center',
                    },
                    {
                        label: "年级",
                        align: 'center',
                        prop: "gradeName",
                    },
                    {
                        label: "学年",
                        align: 'center',
                        prop: "yearName",
                    },
                    {
                        label: "学期",
                        prop: "semester",
                        align: 'center',
                        type: 'function',
                        callBack (row) {
                            let parentName = "";
                            if(row.semester){
                                parentName = row.semester == "1" ? "上学期" : "下学期";
                            }else{
                                parentName = '-'
                            }
                            return `<span>${parentName}</span>`

                        },
                    },
                    {
                        label: "考试类型",
                        prop: "examTypeName",
                        align: 'center',
                        labelWidth: "140"
                    },
                    {
                        label: "同步家长",
                        slotName: 'tbFamily',
                        type: 'slot',
                        labelWidth: "150",
                        align: 'center',
                    },
                    {
                        label: "同步状态",
                        labelDescription: "成绩同步状态",
                        slotName: 'syncScoreStatusCloud',
                        type: 'slot',
                        labelWidth: "150",
                        align: 'center',
                    },
                    {
                        label: "导入状态",
                        labelDescription: "成绩导入状态",
                        slotName: 'importState',
                        type: 'slot',
                        labelWidth: "150",
                        align: 'center',
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        labelWidth: "180",
                        align: 'center',
                    }
                ],
                check: true,
                height: ""
            },
            // 表格数据
            table_data: [],
            // 表格选中数据
            tableListSel: [],
            // 表头字体
            headerColor: "#595959",
            // 考试设置相关数据
            examSettingObj: {
                title: "考试设置",
                dialogVisible: false,
                width: "70%",
            },
            examSettingData: {},
            resetData: {},
            // 修改类型
            setTypeDialog: {
                title: "批量更改考试类型",
                dialogVisible: false,
                width: "440px",
            },
            setTypeFormData: {
                ExamType: '',
            },
            setTypeForm: {
                examType: "",
            },
            setType: {
                examType: [
                    {
                        required: true,
                        message: "请选择考试类型",
                        trigger: "change",
                    },
                ]
            },

            setDialog: {
                title: "编辑考试",
                dialogVisible: false,
                width: "640px",
            },
            setRulesFormData: {
                teachingSubjects: [],
                ExamType: [],
                schoolYear: [],
                gradeList: [],
            },
            setRulesForm: {
                id: "",
                examName: "",
                schoolYear: "",
                yearName: "",
                semester: "",
                semesterName: "",
                gradeId: "",
                gradeName: "",
                examType: "",
                examTime: "",
                examTypeName: "",
                examSubjects: [],
                // msco: [],
                examinationMode: "",
                gradeStage: "",
                subjectCode: "",
                fullScore: "", //满分
            },
            setRules: {
                examSubjects: [
                    {
                        required: true,
                        message: "请选择考试科目",
                        trigger: "change",
                    },
                ],
                examinationMode: [
                    {
                        required: true,
                        message: "请选择考试模式",
                        trigger: "change",
                    },
                ],
                // V1.5版本去掉学年必填
                // schoolYear: [
                //   {
                //     required: true,
                //     message: "请选择转学年",
                //     trigger: "change",
                //   },
                // ],
                semester: [
                    {
                        required: true,
                        message: "请选择学期",
                        trigger: "change",
                    },
                ],
                gradeId: [
                    {
                        required: true,
                        message: "请选择年级",
                        trigger: "change",
                    },
                ],
                examType: [
                    {
                        required: true,
                        message: "请选择考试类型",
                        trigger: "change",
                    },
                ],
                examTime: [
                    {
                        required: true,
                        message: "请选择考试时间",
                        trigger: "change",
                    },
                ],
                fullScore: [
                    {
                        required: true,
                        message: "请输入满分",
                        trigger: "change",
                    },
                ],
            },
            listQuery: {
                schoolId: this.$store.state.schoolId,
                gradeId: "",
                examId: "",
                pageNum:1,
                pageRow: 20,
                examName: '',
                examType: '',
                sourceType: ''
            },
            examStatus: "1",
            syncScoreStatusCloud: "",
            // 同步考试弹窗相关
            syncExamDialogObj: {
                title: "同步考试(云阅)",
                dialogVisible: false,
                width: "640px",
            },
            syncExamGradeList: [],
            syncExamListQuery: {
                gradeName: [],
                examName: "",
                startTregionfront: "",
                startTregionafter: "",
                schoolId: "",
            },
            syncExamList: [],
            syncExamParams: {
                schoolId: "",
                list: [],
            },
            // 同步云校弹窗相关
            syncYxExamDialogObj: {
                title: "同步考试(云校)",
                dialogVisible: false,
                width: "640px",
            },
            syncKtExamDialogObj: {
                title: "同步考试(智慧课堂)",
                dialogVisible: false,
                width: "440px",
            },
            syncYxSchoolDialogObj: {
                title: "选择学校",
                dialogVisible: false,
                width: "500px",
            },
            yyjSchoolList: [],
            syncYxExamListQuery: {
                examName: "",
                cloudStartTime: "",
                cloudEndTime: "",
                schoolId: "",
                isExam: "",
            },
            syncYxExamList: [],
            syncYxExamParams: {
                schoolId: "",
                isExam: "",
                list: [],
            },
            syncKtExamParams: {
                schoolId: "",
                zhktStartTime: "",
                zhktEndTime: "",
            },
            zhktDate: [],
            showExamToFamilyNumber: false,
            // 修改类型
            typeDisabled:true
        };
    },
    computed: {
        ...mapState({
            userId: (state) => state.userId,
            permissions: (state) => state.permissions,
        }),
        emptyUrl () {
            return require("../../assets/images/empty.png");
        },
        upLoadAction () {
            return "/api/school/schoolExam/writeStuOnlyCodeByExcel";
        },
        headers () {
            return {
                Authorization: getToken(),
            };
        },
    },
    created () {
        this.initData();
        this.init();
    },
    activated () {
        this.initData();
    },
    methods: {
        /**
         * @Description: 导入弹窗关闭
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:36:59
         */
        handleImportClose() {
            this.importDialogObj.dialogVisible = false;
            this.showDownload = true;
            this.importActive = 0;
            // this.importForm.file = "";
            Object.assign(
                this.$data.importForm,
                this.$options.data().importForm,
            );
            this.$refs.importRef.$refs.importRef.$refs.upload.clearFiles();
            this.$refs.importRef.$refs.importRef.$data.fileName = "";
        },
        /**
         * @Description: 保存导入的信息
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:37:25
         */
        handleImportSave() {
            let find = this.systemExcelHeader
                .filter((i) => i.required === true)
                .find((item) => this.keyMap[item.label] === "");
            if (find) {
                return this.$message.warning("缺少必须导入字段名称");
            }
            const testModel = new TestModel();
            const file = this.getNewData();
            let formData = new FormData();
            formData.append("file", file);
            formData.append("examId", this.importForm.examId);
            formData.append("schoolId", this.importForm.schoolId);
            formData.append("errType", this.importForm.errType);
            formData.append("sameType", this.importForm.sameType);
            formData.append("isRank", this.importForm.isRank);
            this.importLoading = true;
            testModel.examNewUpload(formData).then(async (res) => {
                this.importLoading = false;
                if (res.data.code === "200") {
                    if (res.data.data > 0) {
                        // 导入成功之后调用计算成绩接口
                        const calculationParam = {
                            schoolId: this.importForm.schoolId,
                            examId: this.importForm.examId,
                        };
                        if (this.importForm.isRank === "1") {
                            this._fet(
                                "/school/schoolExamStatistics/averageCalculation",
                                calculationParam,
                            );
                        } else {
                            this._fet(
                                "/school/schoolExamStatistics/scoreCalculation",
                                calculationParam,
                            );
                        }
                    }
                    this.importActive++;
                    this.successData = res.data.data;
                    await this.setExamStatus(this.importForm.examId, "3");
                    await this.getExamList();
                } else if (res.data.code === "400") {
                    this.$message.error(res.data.msg);
                } else if (res.data.code === "20051") {
                    this.$message.error(res.data.msg);
                } else {
                    this.downloadErrorList(res.data);
                }

            });
        },
        /**
         * @Description: 表头字段下拉选择
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:38:01
         */
        importSelChange() {
            this.uploadExcelHeader.forEach((item) => {
                let find = Object.keys(this.keyMap).find(
                    (k) => this.keyMap[k] === item.label,
                );
                item.disabled = !!find;
            });
        },
        /**
         * @Description: 删除已上传文件
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:39:52
         */
        importUploadRemove() {
            this.importForm.file = "";
        },
        /**
         * @Description: 已上传文件赋值
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:40:18
         * @param {*} file
         */
        async importUploadChange(file) {
            this.importForm.file = file.raw;
            let dataBinary = await this.readFile(file.raw);
            let workBook = XLSX2.read(dataBinary, {
                type: "binary",
                cellDates: true,
            });
            let workSheet = workBook.Sheets[workBook.SheetNames[0]];
            this.tableJsonData = XLSX2.utils.sheet_to_json(workSheet);
            this.uploadExcelHeader = [];
            let header = [];
            const range = XLSX2.utils.decode_range(workSheet["!ref"]);
            for (let c = range.s.c; c <= range.e.c; c++) {
                header.push(XLSX2.utils.encode_col(c) + "1");
            }
            header.forEach((item) => {
                this.uploadExcelHeader.push({
                    label: workSheet[item].v,
                    disabled: false,
                    value: workSheet[item].v,
                });
            });
            this.uploadExcelHeader.forEach((item) => {
                this.keyMap[item.label] = item.label;
                // Object.keys(item).forEach((k) => {
                // this.keyMap[k] = item[k]
                //      })
            });
        },
        /**
         * @Description: 读取文件路径
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:40:58
         * @param {*} file
         */
        readFile(file) {
            return new Promise((resolve) => {
                let reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = (ev) => {
                    resolve(ev.target.result);
                };
            });
        },
        /**
         * @Description: 下载学生成绩模板
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:41:32
         */
        downloadImportTemplate() {
            downloadFile(
                {
                    // url: "/school/schoolExam/template",
                    url: "/school/schoolExam/templateOfCampus",
                    form: {
                        schoolId: this.importForm.schoolId,
                        id: this.importForm.examId,
                        isRank: this.importForm.isRank,
                        rankList: this.importForm.rankList,
                    },
                },
                () => {},
            );
        },
        /**
         * @Description:  下一步
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:41:52
         */
        handleImportNext() {
            if (this.importForm.file === "") {
                return this.$message.warning(this.importInfo.uploadText);
            }
            if (
                this.importActive == 0 &&
                this.importForm.isRank == "1" &&
                this.importForm.rankList.length == 0
            ) {
                return this.$message.warning("请选择导入的排名");
            }

            // console.log(this.keyMap, "obj1");
            setTimeout(() => {
                this.show = true;
            }, 500);
            // console.log(this.keyMap, "this.keyMap2222");
            // console.log(this.uploadExcelHeader, "this.uploadExcelHeader222");
            // console.log(this.systemExcelHeader, "this.systemExcelHeader2222");
            this.importActive++;
        },
        /**
         * @Description:  上一步
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:41:52
         */
        changeCheckd(value){
            this.checked = value
            console.log(value,';---hahah')
            this._fet('/school/schoolUserPreferenceDynamicConfig/save', {
                configType:'zhktScoreToParent',
                config: JSON.stringify({ zhktScoreToParent: value }),
                schoolId: this.schoolId,
                menuId: '040415',
                userId: '-1'
            }).then((res)=>{
                if(res.data.code == 200){
                    this.$message.success('修改成功')
                } else {
                    this.$message.error(res.data.data.msg)
                }
                this.getIsChecked()
            })
            },
        handleImportBefore() {
            this.importActive--;
            this.showDownload = false;
        },
        changeCheckd(value){
            this.checked = value
            console.log(value,';---hahah')
            this._fet('/school/schoolUserPreferenceDynamicConfig/save', {
                configType:'zhktScoreToParent',
                config: JSON.stringify({ zhktScoreToParent: value }),
                schoolId: this.schoolId,
                menuId: '040415',
                userId: '-1'
            }).then((res)=>{
                if(res.data.code == 200){
                    this.$message.success('修改成功')
                } else {
                    this.$message.error(res.data.data.msg)
                }
                this.getIsChecked()
            })
        },
        /**
         * @Description: 打开导入弹窗
         * @Author: 韩俊梅
         * @Date: 2024-08-05 14:38:44
         * @params {String} examId   当前选中数据的examId
         */
        handleImport(examId) {
            this.importForm.examId = examId ?? this.foundationInfo.examId;
            this.importForm.schoolId = this.foundationInfo.schoolId;
            // 获取数据库标准字段
            this.getStandardFields();
            const classManageModel = new ClassManageModel();
            let examIdId = {
                schoolId: this.foundationInfo.schoolId,
                examId: examId,
            };
            this.importRankOptions = [];
            classManageModel.getSubjectList(examIdId).then((res) => {
                if (res.data && res.data.code == "200") {
                    res.data.data.subjectList.forEach((item) => {
                        this.importRankOptions.push(
                            item.examSubjectsName + "班级排名",
                        );
                        this.importRankOptions.push(
                            item.examSubjectsName + "年级排名",
                        );
                    });
                    let examIndex =
                        this.importRankOptions.indexOf("总分班级排名");
                    let examIndex2 =
                        this.importRankOptions.indexOf("总分年级排名");
                    if (examIndex == -1 && examIndex2 == -1) {
                        this.importRankOptions.push("总分班级排名");
                        this.importRankOptions.push("总分年级排名");
                    }
                    // console.log(
                    //     "this.importRankOptions",
                    //     this.importRankOptions,
                    // );
                    this.importDialogObj.dialogVisible = true;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 获取新生成的数据
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:42:37
         */
        getNewData() {
            let newData = [];
            this.tableJsonData.forEach((item) => {
                let obj = {};
                this.uploadExcelHeader.forEach((itm) => {
                    const find = Object.keys(this.keyMap).find(
                        (i) => this.keyMap[i] === itm.label,
                    );
                    if (find) {
                        obj[find] = item[this.keyMap[find]];
                    } else {
                        obj[itm.label] = item[itm.label];
                    }
                });
                newData.push(obj);
            });
            const ws = XLSX2.utils.json_to_sheet(newData);
            const wb = XLSX2.utils.book_new();
            XLSX2.utils.book_append_sheet(wb, ws, "成绩导入模板.xlsx");
            let arrayBuffer = XLSX2.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
            });
            // XLSX2.writeFile(wb, '成绩导入模板.xlsx')
            return new File([arrayBuffer], "成绩导入模板.xlsx");
        },
        /**
         * @Description: 数据表数据错误时下载错误文件
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:42:55
         * @param {Object} data  下载文件对应的整条数据
         */
        downloadErrorList(data) {
            if (data.info.rightCount > 0) {
                this._fet("/school/schoolExamStatistics/scoreCalculation", {
                    schoolId: this.importForm.schoolId,
                    examId: this.importForm.examId,
                });
            }
            this.$confirm(data.msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    downloadFile(
                        {
                            url: "/school/schoolExam/examErrorExport",
                            method: "post",
                            fileName: "学生导入错误信息表.xlsx",
                            form: {paramData: data.info.paramData},
                        },
                        () => {},
                        () => {},
                    );
                })
                .catch(() => {});
        },
        /**
         * @Description: 获取数据库标准字段
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:44:10
         */
        getStandardFields() {
            const classManageModel = new ClassManageModel();
            let examIdId = {
                schoolId: this.foundationInfo.schoolId,
                id: this.importForm.examId,
            };
            classManageModel.getStandard(examIdId).then((res) => {
                this.handleRes(res, () => {
                    let gradeId = this.$store.state.gradeLevel;
                    console.log(gradeId, "级部、年级、班");
                    if (gradeId === "6" || gradeId === "7") {
                        this.systemExcelHeader = [
                            {
                                label: "级部",
                                required: true,
                            },
                            {
                                label: "年级",
                                required: true,
                            },
                            {
                                label: "班级",
                                required: true,
                            },
                            {
                                label: "姓名",
                                required: true,
                            },
                            {
                                label: "唯一号",
                                required: true,
                            },
                        ];
                    } else {
                        this.systemExcelHeader = [
                            {
                                label: "年级",
                                required: true,
                            },
                            {
                                label: "班级",
                                required: true,
                            },
                            {
                                label: "姓名",
                                required: true,
                            },
                            {
                                label: "唯一号",
                                required: true,
                            },
                        ];
                    }

                    res.data.data.forEach((item) => {
                        this.systemExcelHeader.push({
                            label: item,
                            required: false,
                        });
                    });
                    this.systemExcelHeader.forEach((item) => {
                        this.keyMap[item.label] = "";
                    });
                });
            });
        },
        /**
         * @Description: 给家长发送成绩
         * @Author: 韩剧你们
         * @Date: 2024-08-05 17:44:31
         * @param {Object} data  选中当条数据全部信息
         */
        sendResults(data) {
            console.log(data)
            const classManageModel = new ClassManageModel();
            classManageModel.sendResults({
                schoolId: this.foundationInfo.schoolId,
                examId: data.id,
            })
            .then((res) => {
                this.handleRes(res, () => {
                    // 获取考试列表
                    this.getExamList();
                    this.$message.success(res.data.data);
                });
            });
        },
        /**
         * @Description: 成绩同步
         * @Author: 韩俊梅
         * @Date: 2024-08-02 16:55:06
         * @param {Object} data  当前数据   data.sourceType  1云校2云阅卷7智慧课堂，3创建
         */
        asyncGrade(data){
            this.foundationInfo.examId = data.id;
            if(data.sourceType == '2'){
                this.asyncAchievementsYunyue(data)
            }else if(data.sourceType == '7'){
                this.asyncAchievementsZhkt(data)
            }else{
                this.asyncAchievements(data)
            }
        },
         /**
          * @Description: 云阅卷同步考试成绩
          * @Author: 韩俊梅
          * @Date: 2024-08-05 17:45:17
          * @param {Object} data  当前数据
          */
         async asyncAchievementsYunyue(data) {
            // 获取学校详情
            const classManageModel = new ClassManageModel();
            const schoolDetailRes = await classManageModel.getProjectDetail(
                this.schoolId,
            );
            if (!schoolDetailRes.data.code == "200") {
                this.$message.error("获取学校信息错误");
                return;
            }
            const schoolDetail = schoolDetailRes.data.data;
            if (!schoolDetail.examSchoolId) {
                this.$message.error("该学校没有关联云阅卷学校ID");
                return;
            }
            // 判断同步状态
            if (schoolDetail.syncScoreStatus == "2") {
                this.$message.warning("该考试项目正在同步考试信息，请稍后重试");
                return;
            }
            // 同步考试成绩
            const param = {
                yySchoolId: schoolDetail.examSchoolId,
                id: data.id,
                schoolId: this.schoolId,
            };
            classManageModel.synchronizeExamScore(param).then((res) => {
                this.handleRes(res, () => {
                    // this.getExamList()
                    // this.getList(this.examId)
                    this.$message.success(res.data.data);
                    this.getExamList()
                });
            });
        },
        //
        /**
         * @Description: 云校同步成绩
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:45:41
         * @param {Object} data  当前数据   data.sourceType  1云校2云阅卷7智慧课堂，3创建
         */
        asyncAchievements(data) {
            this.$confirm("同步数据可能耗时较长，确实要进行同步吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this._fet("/school/schoolExam/synchronizeCloudScore", {
                        schoolId: this.foundationInfo.schoolId,
                        examName: data.examName,
                        id: data.id,
                    }).then((res) => {
                        if (res.data.code === "200") {
                            this.$message.success(res.data.msg);
                            this.getExamList()
                        } else if (res.data.code === "30019") {
                            this.$message.warning(res.data.msg);
                        } else {
                            this.$message.error("同步失败");
                        }
                    });
                })
                .catch(() => {});
        },
        /**
         * @Description: 同步智慧课堂成绩
         * @Author: 韩俊梅
         * @Date: 2024-08-07 10:53:23
         * @param {Object} data 当前选中数据
         */
        asyncAchievementsZhkt(data) {
            this.$confirm("同步数据可能耗时较长，确实要进行同步吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this._fet("/school/schoolExam/synchronizeZhktScoreInfoForCloud", {
                        productId: "cloud_campus",
                        schoolId: this.foundationInfo.schoolId,
                        oldExamId: data.oldExamId,
                        id: data.id,
                    }).then((res) => {
                        if (res.data.code === "200") {
                            this.$message.success(res.data.data);
                            this.getExamList()
                        } else if (res.data.code === "30019") {
                            this.$message.warning(res.data.msg);
                        } else {
                            this.$message.error("同步失败");
                        }
                    });
                })
                .catch(() => {});
        },
        /**
         * @Description: 本页全选
         * @Author: 韩俊梅
         * @Date: 2024-08-02 11:04:26
         * @param {*} data 当前页列表数据
         */
        chooseAll(data){
            if(data.length == this.tableListSel.length){
                this.$refs.table.$refs.multipleTable.clearSelection();
            }else{
                this.$refs.table.$refs.multipleTable.toggleAllSelection();
            }
        },
        /**
         * @Description: 表格全选
         * @Author: 韩俊梅
         * @Date: 2024-08-02 09:41:43
         */
        handleSelectionChange (data) {
            this.tableListSel = data;
            if(this.tableListSel.length == this.examList.length){
                this.checkedList = true
            }else{
                this.checkedList = false
            }
        },
        tableBtnClick (data, index) {
            console.log('index',index)
            //存储筛选项及滚动条高度
            data.rowIndex = index
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery:  this.listQuery,
                data:data
            })

            console.log(recordsList.get(this.$route.name),'获取--存储筛选项及滚动条高度');
            this.$emit("change", {
                name: "ClassManageAnalysisDetails",
                data: {
                    ...data,

                },
            });
        },
        clickBtn (data) {
            switch (data.item.fn) {
                case 'primary': //查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value
                    });
                    this.listQuery.pageNum = 1
                    this.getExamList()
                    break;
                case 'reset': //重置
                    this.formData.data.forEach((item) => {
                        item.value = ''
                        this.listQuery[item.key] = ''
                    });
                    this.listQuery.pageNum = 1
                    this.getExamList()
                    break;

                default:
                    break;
            }
        },
        initData () {
            console.log(this.$route, '跳转过来携带的参数');
            if (this.$route.params && this.$route.params.source === "AchievementList") {
                console.log(this.$route.params, '99999999999999');
                Object.keys(this.$route.params).forEach((key) => {
                    this.foundationInfo[key] = this.$route.params[key];
                })
                this.tableBtnClick(this.$route.params, 0);
                this.templateStatus = "5"
            } else {
                this.templateStatus = "0"
            }

        },
        /**
        * @Description: 获取智慧课堂同步家长配置
        * @Author: 王梦瑶
        * @Date: 2024-07-30 09:54:22
        */
        getIsChecked () {
            this._fet('/school/schoolUserPreferenceDynamicConfig/getUserDetail', {
                configType: 'zhktScoreToParent',
                schoolId: this.schoolId,
                menuId: '040415'
            }).then((res) => {
                if (res.data.code == 200) {
                    this.checked1 = res.data.data.config ? String(JSON.parse(res.data.data.config).zhktScoreToParent) : '0'
                } else {
                    this.checked1 = '0'
                }
            })
        },
       /**
         * @Description: 修改 checked 是否智慧课堂成绩同步家长
         * @Author: 韩俊梅
         * @Date: 2024-08-05 10:14:09
         * @param {string} value   1/0  代表是否发送
         */
        changeCheckd (value) {
            this.checked1 = value
            console.log(value,this.checked1, ';---hahah')
            this._fet('/school/schoolUserPreferenceDynamicConfig/save', {
                configType: 'zhktScoreToParent',
                config: JSON.stringify({ zhktScoreToParent: value }),
                schoolId: this.schoolId,
                menuId: '040415',
                userId: '-1'
            }).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success('修改成功')
                } else {
                    this.$message.error(res.data.data.msg)
                }
                this.getIsChecked()
            })
        },
        /**
         * @Description: 删除已选中的考试
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:47:52
         * @param {Object} data 当条要删除的数据
         */
        delExam (data) {
            this.$confirm("此操作将永久删除该考试, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this._fet("/school/schoolExam/delete", {
                        schoolId: this.schoolId,
                        id: data.id,
                    }).then((res) => {
                        this.handleRes(res, async () => {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            let t =
                                data.id === this.foundationInfo.examId ? 0 : 1;
                            this.getExamList(t);
                        });
                    });
                })
                .catch(() => { });
        },
        /**
         * @Description: 编辑已选中的考试
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:47:52
         * @param {Object} data 当条要编辑的数据
         */
        editExam (data) {
            this.setRulesFormData.teachingSubjects.forEach((item) => {
                if (data.subjectCode) {
                    let subjectCode = data.subjectCode.split(",");
                    if (subjectCode.find((i) => i === item.code)) {
                        item.sel = true;
                    }
                }
            });
            Object.keys(this.setRulesForm).forEach((key) => {
                this.setRulesForm[key] = data[key];
            });
            let find = this.setRulesFormData.gradeList.find(
                (i) => i.id === this.setRulesForm.gradeId,
            );
            if (find) this.setRulesForm.gradeStage = find.greadStage;
            this.setRulesForm.semesterName =
                this.setRulesForm.semester === "1" ? "上学期" : "下学期";
            if (this.checkautha()) {
                this.setDialog.dialogVisible = true;
                this.setDialog.title = '编辑考试'
            }
        },
        checkautha () {
            return this.permissions.find((item) => {
                return item == "achievement:examAdd";
            });
        },
        checkauth () {
            return this.permissions.find((item) => {
                return item == "achievement:examSetting";
            });
        },
        compare (property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value2 - value1;
            };
        },
        /**
         * @Description: 查看考试详情
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:47:52
         * @param {Object} data 当条要查看的数据
         * @param {Number} type  类型
         */
        async settingExam (data, type = 0) {
            await this._fet(
                "/school/schoolExamScoreSetting/getDetailExamSetting",
                {
                    schoolId: this.schoolId,
                    examId: data.id,
                },
            ).then((res) => {
                this.handleRes(res, () => {
                    this.examSettingData = res.data.data;
                    let scoreList = this.examSettingData.scoreList; // 考试设置返回scoreList
                    this.examSettingData.scoreList = [];
                    this.examSettingData.examSubjects
                        .split(",")
                        .forEach((item) => {
                            // if (scoreList.map(i => i.examSubjectsName).includes(item)) {
                            //   let find = scoreList.find(i => i.examSubjectsName === item)
                            //   this.examSettingData.scoreList.push(scoreList.find(i => i.examSubjectsName === item))
                            // } else {
                            let find = scoreList.find(
                                (i) => i.examSubjectsName === item,
                            );
                            let examSubjects =
                                this.setRulesFormData.teachingSubjects.find(
                                    (i) => i.name === item,
                                );
                            this.examSettingData.scoreList.push({
                                examSubjectsName: item,
                                examSubjects: examSubjects
                                    ? examSubjects.code
                                    : "",
                                excellentPercentage:
                                    find && find.excellentPercentage
                                        ? find.excellentPercentage
                                        : 80,
                                lowPercentage:
                                    find && find.lowPercentage
                                        ? find.lowPercentage
                                        : 40,
                                passPercentage:
                                    find && find.passPercentage
                                        ? find.passPercentage
                                        : 60,
                                fullScore:
                                    find && find.fullScore
                                        ? find.fullScore
                                        : ["语文", "数学", "英语"].includes(
                                            item,
                                        )
                                            ? "150"
                                            : "100",
                            });
                            // }
                        });
                    this.examSettingData.levelList.sort(
                        this.compare("highPercentage"),
                    );
                    this.examSettingData.levelList.forEach((item, index) => {
                        if (index === 0) {
                            item.higthReadOnly = true;
                        }
                        if (
                            index ===
                            this.examSettingData.levelList.length - 1
                        ) {
                            item.lowReadOnly = true;
                        }
                    });
                    this.resetData = JSON.parse(
                        JSON.stringify(this.examSettingData),
                    );
                    // type === 1 编辑考试自动保存
                    if (type === 0 && this.checkauth()) {
                        this.examSettingObj.dialogVisible = true;
                        console.log('设置考试弹窗出现');
                    }
                });
            });
        },
        /**
         * @Description:  删除考试
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:50:51
         * @param {*} index  选中数据的下表
         */
        examSettingDl (index) {
            this.examSettingData.levelList.splice(index, 1);
        },
        /**
         * @Description: 添加考试
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:51:41
         */
        examSettingAdd () {
            let length = this.examSettingData.levelList.length;
            if (length === 10) return this.$message.warning("最多设置10个等级");
            let previewName =
                length === 0
                    ? "[90-100]"
                    : `[${100 - (length + 1) * 10}-${100 - length * 10}]`;
            this.examSettingData.levelList.push({
                levelName: "",
                previewName: previewName,
                highPercentage: "",
                lowPercentage: "",
            });
        },
        /**
         * @Description:  重置考试
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:51:55
         */
        examSettingReset () {
            this.examSettingData = JSON.parse(JSON.stringify(this.resetData));
        },
        /**
         * @Description: 提交设置考试
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:52:26
         * @param {*} data
         */
        examSettingSubmit (data) {
            let arr = data.levelList;
            for (let i = 0; i < arr.length - 1; i++) {
                if (
                    Number(arr[i].lowPercentage) <
                    Number(arr[i + 1].highPercentage)
                ) {
                    return this.$message.warning("最高分、最低分区间重复");
                }
                if (
                    Number(arr[i].lowPercentage) >
                    Number(arr[i + 1].highPercentage)
                ) {
                    return this.$message.warning("等级区间不连续");
                }
            }
            data.examSubjectsName = data.examSubjects;
            data.examSubjects = data.scoreList
                .map((i) => i.examSubjects)
                .toString();
            this._fet("/school/schoolExamScoreSetting/save", data).then(
                (res) => {
                    this.handleRes(res, async () => {
                        this.$message.success("考试设置保存成功！");
                        this.examSettingObj.dialogVisible = false;
                        // this.importDialogObj.dialogVisible = true;
                        await this.setExamStatus(data.examId, "2");
                        this._fet(
                            "/school/schoolExamStatistics/scoreCalculation",
                            {
                                schoolId: this.importForm.schoolId,
                                examId: this.importForm.examId,
                            },
                        );
                    });
                },
            );
        },
        /**
         * @Description: 关闭设置考试弹窗
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:52:54
         * @param {Object} data 考试设置弹窗字段
         */
        handleClose (data) {
            data.dialogVisible = false;
        },
        async init () {
            let recordsList = new RecordsList();
            if(recordsList.get(this.$route.name)){
                this.formData = recordsList.get(this.$route.name).formData;
                this.listQuery = recordsList.get(this.$route.name).listQuery;
            }
            console.log('this.listQuery',this.listQuery);
            // 初始化变量赋值
            this.foundationInfo.userId = this.userId;
            this.foundationInfo.schoolId = this.$store.state.schoolId;
            this.schoolId = this.$store.state.schoolId;
            this.importForm.schoolId = this.$store.state.schoolId;
            // 左侧考试筛选
            this.examinationScreening.schoolId = this.$store.state.schoolId;
            // 获取依赖数据
            await this.getDependencyData();
            this.setDefaultZhktDate();
            this.getIsChecked()
        },
        /**
         * @Description: 初始化数据
         * @Author:韩俊梅
         * @Date: 2024-08-06 09:04:45
         */
        async getDependencyData () {
            await this.getExamList(); // 获取考试列表
            await this.getClassList(); // 年级下拉
            await this.getTypeList(); // 类型下拉
            await this.getSetDialogData(); //获取创建考试依赖数据
        },
        /**
         * @Description: 获取考试列表
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:53:11
         * @param { Number || 0 } t  默认选择第一个
         */
        async getExamList (t = 0) {
            this.loadingTable = true
            await this._fet("/school/schoolExam/list4Page",this.listQuery).then((res) => {
                this.handleRes(res, () => {
                    this.loadingShow = false
                    this.loadingTable = false
                    this.examList = res.data.data.rows;
                    this.total = res.data.data.total;
                    if (this.examList.length > 0 && t === 0) {
                        this.foundationInfo.name = this.examList[0].examName;
                        this.examId = this.examList[0].id;
                        this.examStatus = this.examList[0].examStatus;
                        this.syncScoreStatusCloud =
                            this.examList[0].syncScoreStatusCloud;
                        this.foundationInfo.examTypeName =
                            this.examList[0].examTypeName;
                        this.foundationInfo.examId = this.examList[0].id;
                        this.foundationInfo.gradeId = this.examList[0].gradeId;
                        this.foundationInfo.examinationMode =
                            this.examList[0].examinationMode;
                        this.foundationInfo.oldExamId = this.examList[0].oldExamId;
                        this.importForm.examId = this.examList[0].id;
                        this.importForm.schoolId = this.foundationInfo.schoolId;
                    }
                });
            });
        },
        /**
         * @Description: 年级下拉列表
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:53:29
         */
        getClassList () {
            this._fet("/school/schoolOrgan/listByCondition", {
                schoolId: this.schoolId,
                organType: "3",
            }).then((res) => {
                this.handleRes(res, () => {
                    this.setRulesFormData.gradeList = res.data.data.list;
                    this.examinationScreening.gradeIdList = res.data.data.list;
                    this.formData.data.forEach((item) => {
                        if (item.key === 'gradeId') {
                            item.list = res.data.data.list.map(i => ({ label: i.name, value: i.id }))
                        }
                    })
                });
            });
        },
        /**
         * @Description: 类型下拉
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:53:47
         */
        getTypeList () {
            this._fet("/school/schoolDictValue/listByCondition", {
                schoolId: this.schoolId,
                dictKey: "examType",
            }).then((res) => {
                this.handleRes(res, () => {
                    this.examinationScreening.examTypeList = res.data.data;
                    this.formData.data.forEach((item) => {
                        if (item.key === 'examType') {
                            item.list = res.data.data.map(i => ({ label: i.name, value: i.id }))
                        }
                    })
                    this.setTypeFormData.ExamType = res.data.data;

                });
            });
        },
        /**
         * @Description: 获取创建考试依赖数据
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:54:08
         */
        getSetDialogData () {
            ["teachingSubjects", "ExamType", "schoolYear"].forEach((item) => {
                this.getDictList(item);
            });
        },
        /**
         * @Description:  获取模板
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:54:48
         * @param {*} dictKey 学年信息
         */
        getDictList (dictKey) {
            this._fet("/school/schoolDictValue/listByCondition", {
                schoolId: this.schoolId,
                dictKey: dictKey,
            }).then((res) => {
                this.handleRes(res, () => {
                    if (dictKey === "teachingSubjects") {
                        res.data.data.forEach((item) => {
                            item.sel = false;
                        });
                    }
                    res.data.data.sort((a, b) => {
                        return a.sort - b.sort;
                    });
                    // if(dictKey == 'ExamType') { // 不可创建云阅类型的试卷
                    //   res.data.data = res.data.data.filter(e => e.name != "云阅")
                    // }
                    this.setRulesFormData[dictKey] = res.data.data;
                });
            });
        },
        /**
         * @Description: 创建考试
         * @Author:  韩俊梅
         * @Date: 2024-08-05 17:55:22
         */
        async createExam () {
            if (this.checkautha()) {
                this.setDialog.dialogVisible = true;
                this.setDialog.title = '创建考试'
            }
        },
        /**
         * @Description: 修改类型弹窗关闭
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:57:10
         */
        handleTypeClose(){
            this.$refs.setTypeRef.$refs.formRef.resetFields();
            Object.keys(this.setTypeForm).forEach((key) => {
                this.setTypeForm[key] = "";
            });
            this.setTypeDialog.dialogVisible = false;
        },
        /**
         * @Description: 保存修改的类型
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:57:10
         */
        handleSetTypeSave(){
            var idList = []
            for (let i = 0; i < this.tableListSel.length; i++) {
                idList.push(this.tableListSel[i].id)
            }
            this._fet("/school/schoolExam/editExamType", {
                examType:this.setTypeForm.examType,
                examIdList:idList
            }).then(
                (res) => {
                    this.handleRes(res, async () => {
                        this.$message.success("类型修改成功！");
                        // 保存成功后调用关闭方法清空数据
                        this.handleTypeClose()
                        this.getExamList()
                    });
                },
            );
        },
        /**
         * @Description: 打开修改类型列表
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:57:10
         */
        creatType(){
            this.setTypeDialog.dialogVisible = true;
        },
        /**
         * @Description: 关闭创建弹窗
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:57:10
         */
        handleSetClose: throttle(function () {
            this.$refs.setRef.$refs.formRef.resetFields();
            Object.keys(this.setRulesForm).forEach((key) => {
                this.setRulesForm[key] = "";
            });
            this.setRulesFormData.teachingSubjects.forEach((item) => {
                item.sel = false;
            });
            this.setDialog.dialogVisible = false;
        }, 1000),
        /**
         * @Description: 保存创建
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:57:10
         */
        async handleSetSave () {
            this.setRulesForm.schoolId = this.schoolId;
            if (this.setRulesForm.examTime) {
                this.setRulesForm.examTime = this.setRulesForm.examTime.slice(0, 10) + ' 00:00:00'
            }
            if (this.setRulesForm.id === "") {
                // 创建考试
                this._fet("/school/schoolExam/save", this.setRulesForm).then(
                    (res) => {
                        this.handleRes(res, async () => {
                            this.$message.success("考试创建成功！");
                            await this.setExamStatus(res.data.data.id, "1");
                            await this.getExamList();
                            this.handleSetClose();
                        });
                    },
                );
            } else {
                // 编辑考试
                this._fet("/school/schoolExam/edit", this.setRulesForm).then(
                    (res) => {
                        this.handleRes(res, async () => {
                            this.$message.success("考试编辑成功！");
                            let t =
                                res.data.data.id === this.foundationInfo.examId
                                    ? 0
                                    : 1;
                            await this.settingExam(
                                { id: this.setRulesForm.id },
                                1,
                            );
                            //点击编辑时，走了编辑也走了【考试设置保存接口】所以这里注释掉。如若设计如此打开注释即可
                            // await this.examSettingSubmit(this.examSettingData);
                            await this.getExamList(t);
                            this.handleSetClose();
                        });
                    },
                );
            }
        },
        /**
         * @Description: 接口请求结果处理
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:57:10
         */
        handleRes (res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        /**
         * @Description: 同步考试云阅卷弹窗打开
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:59:16
         */
        async syncExamShow () {
            this.syncExamListQuery.schoolId = this.schoolId;
            let res = await this._fet(
                "/school/schoolExam/queryGradeInfoBySid",
                {
                    schoolId: this.syncExamListQuery.schoolId,
                },
            );
            this.handleRes(res, async () => {
                // console.log("获取年级列表", res);
                this.syncExamGradeList = res.data.data;
                this.syncExamDialogObj.dialogVisible = true;
                await this.syncExamQuery();
            });
        },
         /**
         * @Description: 同步考试弹窗关闭
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:59:16
         */
        handleSyncExamDialogClose () {
            this.syncExamDialogObj.dialogVisible = false;
            this.syncExamReset();
        },
        /**
         * @Description: 查询同步考试列表
         * @Author: 韩俊梅
         * @Date: 2024-08-05 17:59:16
         */
        async syncExamQuery () {
            let params = Object.assign({}, this.syncExamListQuery, {
                gradeName: JSON.parse(
                    JSON.stringify(this.syncExamListQuery.gradeName),
                ).join(";"),
            });
            let res = await this._fet(
                "/school/schoolExam/queryExamListByCondition",
                params,
            );
            this.handleRes(res, () => {
                // console.log("同步考试获取列表", res);
                this.syncExamList = res.data.data;
            });
        },
        /**
         * @Description: 考试重置
         * @Author: 韩俊梅
         * @Date: 2024-08-05 18:00:41
         */
        syncExamReset () {
            Object.assign(
                this.$data.syncExamListQuery,
                this.$options.data().syncExamListQuery,
                { schoolId: this.schoolId },
            );
        },
        /**
         * @Description: 同步考试下拉选择
         * @Author: 韩俊梅
         * @Date: 2024-08-05 18:01:02
         * @param {String} selected 已选择的值
         */
        syncExamSelChange (selected) {
            this.syncExamParams.list = selected;
        },
        /**
         * @Description: 保存考试同步 云阅卷提交
         * @Author: 韩俊梅
         * @Date: 2024-08-05 18:02:12
         */
        syncExams () {
            if (this.syncExamParams.list.length == 0) {
                return this.$message.warning("请选择考试");
            }
            this.syncExamParams.schoolId = this.schoolId;
            this._fet(
                "/school/schoolExam/synchronizeExamInfoByCondition",
                this.syncExamParams,
            ).then((res) => {
                this.handleRes(res, () => {
                    this.$message.success(res.data.data);
                    this.syncExamDialogObj.dialogVisible = false;
                });
            });
        },
        /**
         * @Description 判断当前学校是否需要选择云校学校目前只有schoolId=87存在这种情况
         * @Author 韩熙昊
         * @Date 2022/11/7 14:17
         **/
        syncYxExamShow () {
            this.syncYxExamListQuery.schoolId = this.schoolId;
            this.syncYxExamDialogObj.dialogVisible = true;
        },
        /**
         * @Description 确认选择对应的云校学校
         * @Author 韩熙昊
         * @Date 2022/11/7 14:17
         **/
        confirmYyjSchool () {
            if (this.syncYxExamListQuery.schoolId === "") {
                return this.$message.warning("请选择云阅卷学校");
            }
            this.syncYxSchoolDialogObj.dialogVisible = false;
            this.syncYxExamDialogObj.dialogVisible = true;
        },
        /**
         * @Description 取消选择对应的云校学校
         * @Author 韩熙昊
         * @Date 2022/11/7 14:17
         **/
        cancelYyjSchool () {
            this.syncYxExamListQuery.schoolId = "";
            this.syncYxSchoolDialogObj.dialogVisible = false;
        },
        /**
         * @Description 关闭同步云校考试弹窗
         * @Author 韩熙昊
         * @Date 2022/11/7 14:15
         **/
        handleSyncYxExamDialogClose () {
            this.syncYxExamDialogObj.dialogVisible = false;
            this.syncYxExamReset();
            this.syncYxExamListQuery.schoolId = "";
        },
        /**
         * @Description 同步云霄考试查询考试列表
         * @Author 韩熙昊
         * @Date 2022/11/7 14:14
         **/
        async syncYxExamQuery () {
            let params = Object.assign({}, this.syncYxExamListQuery);
            if (params.isExam === "") {
                return this.$message.warning("请选择考试类型");
            }
            this.syncYxExamParams.isExam = params.isExam;
            let res = await this._fet(
                "/school/schoolExam/getCloudExamList",
                params,
            );
            this.handleRes(res, () => {
                // console.log("同步考试获取列表", res);
                this.syncYxExamList = res.data.data;
            });
        },
        /**
         * @Description 重置查询条件和考试列表数据
         * @Author 韩熙昊
         * @Date 2022/11/7 14:13
         **/
        syncYxExamReset () {
            const schoolId = this.syncYxExamListQuery.schoolId;
            Object.assign(
                this.syncYxExamListQuery,
                this.$options.data().syncYxExamListQuery,
                { schoolId: schoolId },
            );
            this.syncYxExamList = [];
        },
        /**
         * @Description 获取考试列表中选中的考试数据
         * @Author 韩熙昊
         * @Date 2022/11/7 14:12
         **/
        syncYxExamSelChange (selected) {
            this.syncYxExamParams.list = selected;
        },
        /**
         * @Description 选中需要同步的考试之后，调用接口，同步云校考试提交
         * @Author 韩熙昊
         * @Date 2022/11/7 14:10
         **/
        syncYxExams () {
            if (this.syncYxExamParams.list.length === 0) {
                return this.$message.warning("请选择考试");
            }
            let params = {
                schoolId: this.syncYxExamListQuery.schoolId,
                examIdList: this.syncYxExamParams.list.map((i) => i.examId),
                isExam: this.syncYxExamParams.isExam,
            };

            this._fet("/school/schoolExam/synchronizeCloudExam", params).then(
                (res) => {
                    this.handleRes(res, () => {
                        this.$message.warning(
                            "考试同步中，如果同步失败，请确认考试科目一致后，再次同步",
                        );
                        this.syncYxExamDialogObj.dialogVisible = false;
                    });
                },
            );
        },
        /**
         * @Description: 设置考试状态
         * @Author: 韩俊梅
         * @Date: 2024-08-05 18:02:57
         * @param {*} id  考试id
         * @param {*} status  考试状态
         */
        async setExamStatus (id, status) {
            const classManageModel = new ClassManageModel();
            await classManageModel.setExamStatus({
                id: id,
                examStatus: status,
            });
        },
        /**
         * @Description: 获取考试状态
         * @Author:  韩俊梅
         * @Date: 2024-08-05 18:08:53
         * @param {*} id 考试id
         */
        async getExamStatus (id) {
            let status = "";
            const classManageModel = new ClassManageModel();
            await classManageModel.getExamStatus({ id: id }).then((res) => {
                if (res.data.code === "200" || res.data.data.examStatus) {
                    status = res.data.data.examStatus;
                }
            });
            return status;
        },
        /**
         * @Description: 展示智慧课堂
         * @Author: 韩俊梅
         * @Date: 2024-08-05 18:09:23
         */
        async syncKtExamShow () {
            this.syncKtExamDialogObj.dialogVisible = true;
        },
        /**
         * @Description: 关闭智慧课堂
         * @Author: 韩俊梅
         * @Date: 2024-08-05 18:09:23
         */
        handleSyncKtExamDialogClose () {
            this.syncKtExamDialogObj.dialogVisible = false;
        },
        /**
         * @Description: 提交智慧课堂
         * @Author: 韩俊梅
         * @Date: 2024-08-05 18:09:23
         */
        async confirmKtSchool () {
            if (this.zhktDate.length > 0) {
                let params = {
                    zhktStartTime: this.zhktDate[0],
                    zhktEndTime: this.zhktDate[1],
                    schoolId: this.schoolId,
                    productId: "cloud_campus"
                }
                let res = await this._fet("/school/schoolExam/synchronizeZhktExamInfoForCloud", params,);

                if (res.data.code == "200") {
                    this.$message.success(res.data.data);
                    this.handleSyncKtExamDialogClose();
                } else {
                    this.$message.error(res.data.msg || "同步智慧课堂成绩失败");
                }
            } else {
                this.$message.error("请先选择时间");
            }
        },
        /**
         * @Description: 取消同步智慧课堂
         * @Author: 韩俊梅
         * @Date: 2024-08-05 18:09:23
         */
        cancelKtSchool () {
            this.handleSyncKtExamDialogClose();
        },
        /**
         * @Description: 设置默认时间
         * @Author: 韩俊梅
         * @Date: 2024-08-05 18:10:24
         */
        setDefaultZhktDate () {
            let startTime = this.$moment().startOf('month').format("YYYY-MM-DD");
            let endTime = this.$moment().format("YYYY-MM-DD");
            this.zhktDate = [startTime, endTime];
        }

    },
    watch: {
        'tableListSel': {
            handler() {
                if(this.tableListSel.length > 0) {
                    this.typeDisabled = false
                }else{
                    this.typeDisabled = true
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 127;
        } )

    }
};
</script>
<style lang="scss" scoped>
.center-main {
    padding: 0;
}

.content-wrapper {
    padding-right: 10px;
}


.el-form /deep/ .el-form-item {
    width: 100%;
}

.el-form /deep/ .el-form-item__content {
    width: 80%;
}

.filter-wrap1 {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    // overflow: hidden;
    justify-content: space-between;
    >div{
        display: flex;
        .line{
            width: 1px;
            height: 30px;
            border-left: 1px dashed  #D8D8D8;
            margin-top: 2px;
            margin-right: 20px;
        }
    }

    .expand-filter {
        padding: 0;
    }

    .right-button{
        display: flex;
        align-items: center;
        height: 33px;
        .zhkt{
            display: flex;
            align-items: center;
            >*{
                margin-right: 5px;
            }
            >p{
                width: 56px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #2B2F33;
                line-height: 16px;
            }
        }
        .xk-test{
            padding-right: 10px;
        }
    }
}

.table-paging {
    overflow: hidden;
    border-radius: 6px;
    background-color: #ffffff;
    box-sizing: border-box;
}


.flex {
    display: flex;
    align-items: center;
    justify-content: center;

    >div {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 3px;
    }
    .blue {
        background-color: #3C7FFF !important;
    }

    .orange {
        background: #FA7719;

    }
    .purplr {
        background: #CA3CFF;
    }
    .yellow {
        background: #FAC819;
    }
}
.pageFooter{
    position: relative;
    .checkbox{
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 20px;
    }
    .typeButton{
        position: absolute;
        top: 0px;
        left: 100px;
        text-align: center;
        border-radius: 4px;
        font-size: 14px;
        color: #333333;
        border: 1px solid #C7C7C7;
    }
    .active{
        color: #3C7FFF !important;
        border: 1px solid #3C7FFF !important;
    }
}


</style>
